var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"startup__actions"},[_c('v-btn',{staticClass:"btnDownload",attrs:{"color":"#47a4db"},on:{"click":_vm.downloadUsersExcel}},[_vm._v(" Download Excel ")])],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","dense":"","headers":_vm.headers,"items":_vm.users,"items-per-page":10,"footer-props":{
            'items-per-page-options': [10, 50, 100, 150, 200],
        }},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(" "+_vm._s(_vm.dataDayYear(item.created_at))+" ")]),_vm._v(" "+_vm._s(_vm.dataTime(item.created_at))+" ")]}},{key:"item.mail",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"blue--text",on:{"click":function($event){$event.preventDefault();return _vm.fetchUserInfo(item.user_id)}}},'a',attrs,false),on),[_vm._v(_vm._s(item.mail))])]}}],null,true)},[_c('span',[_vm._v("View user`s profile")])])]}},{key:"item.sales_name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.sales_name ? item.sales_name : '-')+" ")]}},{key:"item.status_name",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","dark":"","color":_vm.getColorByStatus(item.status)}},[_vm._v(" "+_vm._s(item.status_name)+" ")])]}},{key:"item.role",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roleAdapter(item.role))+" ")]}},{key:"item.button",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.fetchUserInfo(item.user_id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("View/Edit profile")])])]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-text-field',{attrs:{"type":"integer"},model:{value:(_vm.filter.user_id),callback:function ($$v) {_vm.$set(_vm.filter, "user_id", $$v)},expression:"filter.user_id"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},model:{value:(_vm.filter.mail),callback:function ($$v) {_vm.$set(_vm.filter, "mail", $$v)},expression:"filter.mail"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},model:{value:(_vm.filter.first_name),callback:function ($$v) {_vm.$set(_vm.filter, "first_name", $$v)},expression:"filter.first_name"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},model:{value:(_vm.filter.last_name),callback:function ($$v) {_vm.$set(_vm.filter, "last_name", $$v)},expression:"filter.last_name"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.roles,"type":"text","item-value":"key","item-text":"value"},model:{value:(_vm.filter.role),callback:function ($$v) {_vm.$set(_vm.filter, "role", $$v)},expression:"filter.role"}})],1),_c('td',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date range","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","range":""},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.resetDate}},[_vm._v(" Clear ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},model:{value:(_vm.filter.sales_name),callback:function ($$v) {_vm.$set(_vm.filter, "sales_name", $$v)},expression:"filter.sales_name"}})],1),_c('td',[_c('v-select',{staticClass:"status_select",attrs:{"type":"text","items":_vm.statuses,"item-text":"name","item-value":"id","name":"name","multiple":""},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1)])]},proxy:true}],null,true)}),_c('user-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }